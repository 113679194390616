import {useQuery} from "@tanstack/react-query";
import {fetchPositions} from "@/api/perpsDataFetcher";
import {AddressZero} from "@ethersproject/constants";
import {bigIntSort, stringSort, Table, TableColumn} from "@/components/Table";
import {PerpPositionStatus, PerpTradeShare} from "@/components/Perps/types";
import {Button, ButtonType} from "@/components/Button";
import {Modal, useModal} from "@/components/Modal";
import React, {useEffect, useState} from "react";
import {ClosePositionView} from "@/components/Perps/closePosition/ClosePositionView";
import {PositionHeader} from "@/components/Perps/positions/PositionHeader";
import {Tooltip as ReactTooltip} from "react-tooltip";
import {SharePerpetualPositionTrade} from "@/components/Sharing/SharePerpetualPositionTrade";
import {MdIosShare} from "react-icons/md";
import {useCurrentUser} from "@/hooks/useCurrentUser";
import {FractionTokenPrice} from "@/components/Perps/FractionTokenPrice";
import {PnLDisplay} from "@/components/Perps/positions/PnLDisplay";
import {multiplyByPrice} from "@/util/converters";
import {useEthPrice} from "@/contexts/EthPriceContext";
import {usePendingTransaction} from "@/contexts/PendingTransactionContext";
import {twMerge} from "tailwind-merge";
import {MobilePosition} from "@/components/Perps/positions/MobilePosition";
import {isAddress} from "viem";
import {SizeView} from "@/components/Perps/positions/SizeView";
import * as Tooltips from "@/util/tooltips"

export const PerpPositionTable = ({onTokenClicked, className}: { onTokenClicked: (token: string) => any, className?: string | undefined }) => {
    const {address} = useCurrentUser();
    const pendingTransaction = usePendingTransaction();

    const query = useQuery({
        queryKey: ['positions', address],
        queryFn: async () => await fetchPositions(address!),
        enabled: !!address,
        refetchInterval: 5 * 1000, // 5 seconds
    });

    useEffect(() => {
        if (!query.isLoading && !query.isRefetching && address) {
            query.refetch();
        }
    }, [pendingTransaction]);

    const [selectedPosition, setSelectedPosition] = useState<PerpPositionStatus>();
    const [perpTradeShareData, setPerpTradeShareData] = useState<PerpTradeShare>();
    const {showInUsd} = useEthPrice();

    const handleShareClick = (positionStatus: PerpPositionStatus) => {
        setPerpTradeShareData({
            id: positionStatus.position.id,
            action: "OPEN",
            side: positionStatus.position.side,
            token: positionStatus.token
        });
        sharePerpetualPositionModal.show();
    };

    const closeModal = useModal();
    const sharePerpetualPositionModal = useModal();

    useEffect(() => {
        if (selectedPosition) {
            closeModal.show();
        } else {
            closeModal.hide();
        }
    }, [selectedPosition]);

    useEffect(() => {
        if (!closeModal.modalVisible) {
            setSelectedPosition(undefined);
        }
    }, [closeModal.modalVisible]);

    const columns: TableColumn<PerpPositionStatus>[] = [
        {
            id: "position",
            label: "Position",
            valueRenderer: d => {
                const id = `perp_share_${d.position.id}`;
                return <div className="flex flex-row items-center gap-2">
                    <div
                      id={id}
                      className="hover:scale-110 text-neutral-content hover:cursor-pointer hover:text-white"
                      onClick={() => handleShareClick(d)}>
                        <MdIosShare size={22}/>
                        <ReactTooltip
                          anchorSelect={`#${id}`}
                          id={`tooltip_${id}`}
                          place="left"
                          noArrow
                          className="z-50"
                          content="Share Your Position"
                          style={{backgroundColor: "#3b485f", color: "#98a2b3"}}
                        />
                    </div>
                    <PositionHeader
                      position={d.position}
                      token={d.token}
                      onTokenClicked={t => onTokenClicked(t.symbol)}/>
                </div>;
            },
            sort: stringSort(d => d.token.symbol),
            span: 3,
        },
        {
            id: "pnl",
            label: "PnL",
            valueRenderer: d => <PnLDisplay positionStatus={d}/>,
            tooltip: Tooltips.ACTIVE_POSITIONS_PNL,
            align: "left",
            span: 2,
        },
        {
            id: "size",
            label: "Size",
            tooltip: Tooltips.ACTIVE_POSITIONS_SIZE,
            valueRenderer: d => <SizeView positionStatus={d}/>,
            sort: bigIntSort((d) => d.position.side === "LONG"
              ? multiplyByPrice(d.position.collateralAmountRaw, d.markPrice)
              : BigInt(d.position.collateralAmountRaw)),
            align: "center",
            span: 2,
        },
        {
            id: "entryPrice",
            label: "Entry Price",
            valueRenderer: d =>
              <FractionTokenPrice
                price={d.position.entryPrice}
                tokenAddress={d.token.address}
                tokenType={d.token.tokenType}
                showInUsd={showInUsd}
                iconSize={12}
                exponentClassName="flex flex-row items-center text-xs text-neutral-content" />,
            tooltip: Tooltips.ACTIVE_POSITIONS_ENTRY_PRICE,
            align: "center",
            span: 2,
        },
        {
            id: "marketPrice",
            label: "Market Price",
            valueRenderer: d =>
              <FractionTokenPrice
                price={d.markPrice}
                tokenAddress={d.token.address}
                tokenType={d.token.tokenType}
                showInUsd={showInUsd}
                iconSize={12}
                exponentClassName="flex flex-row items-center text-xs text-neutral-content" />,
            align: "center",
            tooltip: Tooltips.ACTIVE_POSITIONS_MARKET_PRICE,
            span: 2,
        },
        {
            id: "liquidationPrice",
            label: "Liq. Price",
            valueRenderer: d =>
              <FractionTokenPrice
                price={d.liquidationPrice}
                tokenAddress={d.token.address}
                tokenType={d.token.tokenType}
                showInUsd={showInUsd}
                iconSize={12}
                exponentClassName="flex flex-row items-center text-xs text-neutral-content" />,
            align: "center",
            tooltip: Tooltips.ACTIVE_POSITIONS_LIQ_PRICE,
            span: 2,
        },
        {
            id: "actions",
            label: "",
            valueRenderer: d => {
                return (
                  <div className="flex flex-row items-center justify-between w-full">
                      <Button
                        buttonType={ButtonType.NEUTRAL}
                        onClick={() => setSelectedPosition(d)}
                        className="!border-none !px-2 !py-0 !text-lg !font-normal !capitalize"
                      >
                          Close
                      </Button>
                  </div>
                );
            }
        }
    ];

    const onSuccess = (pnlPositive: boolean, shareTrade: PerpTradeShare) => {
        query.refetch();
        closeModal.hide();
        if (pnlPositive) {
            setPerpTradeShareData(shareTrade);
            sharePerpetualPositionModal.show();
        }
    }

    return (
        <>
            <Table<PerpPositionStatus>
                id="perp_positions"
                className={twMerge("h-full border-none rounded-none no-scrollbar !bg-transparent", className || '')}
                columns={columns}
                data={query.data}
                isLoading={query.isLoading}
                mobileRowRenderer={d =>
                  <MobilePosition
                    handleShareClick={handleShareClick}
                    positionStatus={d}
                    setSelectedPosition={setSelectedPosition}
                    onTokenClicked={onTokenClicked}/>
                }
                emptyText="No active positions, open a position to get started."
            />
            <Modal {...closeModal} title="Close Position">
                { selectedPosition && <div className="p-2">
                    <ClosePositionView positionStatus={selectedPosition} onSuccess={onSuccess}/>
                </div> }
            </Modal>
            {perpTradeShareData && (
                <Modal {...sharePerpetualPositionModal} title="Share">
                    <SharePerpetualPositionTrade perpTradeShare={perpTradeShareData}/>
                </Modal>
            )}
        </>
    )
}
