import {useAccount} from "wagmi";
import {useQuery} from "@tanstack/react-query";
import {getGeofenceData} from "@/api/datafetcher";
import {CHAIN_ID, TEAM_WHITELIST, isMainnet} from "@/util/constants";

export const useGeofence = () => {
  const {address} = useAccount();
  const isWhitelistedAddress = process.env.NODE_ENV === "development" || (address !== undefined && TEAM_WHITELIST.indexOf(address!) >= 0);

  const geofenceData = useQuery({
    queryKey: ['geofence'],
    queryFn: async () => await getGeofenceData(),
    gcTime: 10 * 60 * 1000,
    enabled: isMainnet && !isWhitelistedAddress
  })

  return {
    isLoading: isWhitelistedAddress ? false : geofenceData.isLoading,
    isError: isWhitelistedAddress ? false : geofenceData.isError,
    isSuccess: isWhitelistedAddress || !isMainnet || geofenceData.isSuccess,
    isAllowed: (isWhitelistedAddress || !isMainnet || geofenceData.data?.allowed)
  }
}
