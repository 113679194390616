import classNames from "classnames";
import {FaArrowTrendDown, FaArrowTrendUp} from "react-icons/fa6";
import {capitalizeFirstLetter} from "@/util/helpers";
import React from "react";
import {IoMdSwap} from "react-icons/io";
import {isBlast} from "@/util/constants";

export type PerpSide = "long" | "short" | "swap";

export type Props = {
  side: PerpSide;
  onChange: (side: PerpSide) => void;
}

export const PerpSideSelect = ({ side: currentSide, onChange }: Props) => {
  const renderSideButton = (value: PerpSide) => {
    return (
      <button
        onClick={() => value !== currentSide && onChange(value)}
        className={classNames("capitalize flex flex-row gap-2 items-center justify-center flex-grow py-2 rounded-md font-light text-base", {
          "hover:bg-call/50": value === "long",
          "bg-call/50": value === currentSide && value === "long",
          "hover:bg-put/50": value === "short",
          "bg-put/50": value === currentSide && value === "short",
          "hover:bg-glass-focus": value === "swap",
          "bg-glass-focus": value === currentSide && value === "swap",
        })}>
        {value === "long"
          ? <FaArrowTrendUp />
          : value === "short"
            ? <FaArrowTrendDown/>
            : <IoMdSwap />} {capitalizeFirstLetter(value)}
      </button>
    )
  }

  return (
    <div id="perp-section-side"
         className="h-[65px] border-b border-neutral-content/20 flex flex-row justify-center items-center gap-1 p-2">
      {renderSideButton("long")}
      {renderSideButton("short")}
      {renderSideButton("swap")}
    </div>
  );
}
