import {PerpSide, PerpSideSelect} from "@/components/Perps/openPosition/PerpSideSelect";
import ReactSlider from "react-slider";
import {TokenInput} from "@/components/Perps/TokenInput";
import {capitalizeFirstLetter} from "@/util/helpers";
import {TokenOutput} from "@/components/Perps/TokenOutput";
import {FractionTokenPrice} from "@/components/Perps/FractionTokenPrice";
import classNames from "classnames";
import {FaEthereum} from "react-icons/fa6";
import {formatEther, formatUnits} from "viem";
import React, {useContext, useEffect, useState} from "react";
import {PerpConfig, PerpQuote, TokenType} from "@/components/Perps/types";
import {useQuery} from "@tanstack/react-query";
import {fetchQuote} from "@/api/perpsDataFetcher";
import Switch from "react-switch";
import {theme} from "../../../tailwind.config";
import {useQueryParam} from "@/hooks/useQueryParam";
import {OpenPositionButton} from "@/components/Perps/openPosition/OpenPositionButton";
import {useDebounce} from "@/util/useDebounce";
import {ErrorPanel} from "@/components/ErrorPanel";
import {WarningPanel} from "@/components/WarningPanel";
import {UserBalanceContext} from "@/contexts/UserBalanceContext";
import {useAccount} from "wagmi";
import {SummaryItem} from "@/components/Perps/SummaryItem";
import {ga4AnalyticEvent} from "@/util/analytics";
import {OpenInterestView} from "@/components/Perps/OpenInterestView";
import {SmartOrderView} from "@/components/Perps/SmartOrderView";
import {Tooltip as ReactTooltip} from "react-tooltip";
import {GeofenceLayout} from "@/layouts/GeofenceLayout";
import {useUserSelection} from "@/contexts/UserSelectionContext";
import {SlippageButton} from "@/components/Perps/slippage/SlippageButton";
import {SwapWidget} from "@/components/Swap/SwapWidget";
import {BLAST_TOKEN, BLAST_USDC, isBlast, isEthMainnet} from "@/util/constants";
import {useEthPrice} from "@/contexts/EthPriceContext";
import {BsLightningChargeFill} from "react-icons/bs";
import {FaLock} from "react-icons/fa";
import {useIsDesktop} from "@/hooks/useIsDesktop";
import {useTokenAmount} from "@/hooks/useTokenAmount";
import * as Tooltips from "@/util/tooltips";

export type Props = {
  perpConfig: PerpConfig;
}

const otherSide = (side: PerpSide) => {
  if (side === 'long') {
    return 'short';
  }
  return 'long';
}

export const OpenPositionView = ({ perpConfig }: Props) => {
  const isDesktop = useIsDesktop();
  const { userSelections, handleSelection } = useUserSelection();
  const defaultSide = 'long';
  const [leverage, setLeverage] = useState<number>(perpConfig.parameters.maxLeverage);
  const tokenAmount = useTokenAmount(18);
  const downPayment = tokenAmount.value;
  const [showFullNftView, setShowFullNftView] = useState<boolean>(perpConfig.token.tokenType === TokenType.FLC_FRACTION);
  const [side, setSide] = useQueryParam<PerpSide>('side', userSelections.side || defaultSide, v => (v === 'short' || v === 'swap') ? v : 'long');
  const [speedUp, setSpeedUp] = useState<boolean>(false);
  const nftFractionDigits = isBlast ? 3 : 6;

  useEffect(() => {
    if (perpConfig.token.tokenType !== TokenType.FLC_FRACTION && showFullNftView) {
      setShowFullNftView(false);
    }
    if (perpConfig.parameters.maxLeverage < leverage) {
      setLeverage(perpConfig.parameters.maxLeverage);
    }
  }, [perpConfig.token]);

  const {address} = useAccount();
  const { balance } = useContext(UserBalanceContext);
  const {showInUsd} = useEthPrice();

  const debouncedLeverage = useDebounce(leverage, 100);
  const debouncedDownPayment = useDebounce(downPayment, 300);
  const isUsdb = perpConfig.token.address === BLAST_USDC;
  
  const { data, isError, isLoading, isSuccess } = useQuery({
    queryKey: ["quote", perpConfig.token.address, side, debouncedLeverage, debouncedDownPayment, speedUp],
    queryFn: async () => await fetchQuote(
      perpConfig.token.address,
      isUsdb ? otherSide(side) : side,
      debouncedDownPayment || 0n,
      debouncedLeverage,
      speedUp ? 750 : userSelections.slippage,
      speedUp),
    enabled: side !== 'swap' && debouncedDownPayment !== undefined && debouncedDownPayment > 0,
    gcTime: 20 * 1000, // 20 seconds
    staleTime: 20 * 1000, // 20 seconds
    refetchInterval: 20 * 1000, // 20 seconds
  });

  const renderSwapView = () => {
    // if (isBlast) {
    //   return null;
    // }
    return (
      <SwapWidget initialToken={perpConfig.token} />
    );
  }

  const getOutputSize = () => {
    if (!data) {
      return 0n;
    }
    return isUsdb ? data.outputSizeInETH : data.outputSize;
  }

  const getSymbol = () => {
    let symbol = perpConfig.token.symbol;
    if (showFullNftView) {
      symbol = symbol.replace('μ', '');
    }
    if (symbol === "USDB") {
      symbol = "ETH";
    }
    return symbol;
  }

  const renderOpenPositionButton = () => {
    if (side === 'swap') {
      return null;
    }

    return (
      <div className="flex flex-col gap-1 w-full">
        <div className="p-2">
          <OpenInterestView token={perpConfig.token} />
        </div>
        <GeofenceLayout className="!text-sm md:p-4">
          <OpenPositionButton
            enabled={
              !!address &&
              !data?.errorMessage &&
              !!downPayment &&
              isSuccess &&
              downPayment > 0n &&
              downPayment <= (balance?.toBigInt() || 0n)
            }
            token={perpConfig.token}
            maxSlippage={userSelections.slippage}
            side={isUsdb ? otherSide(side) : side}
            downPayment={downPayment || 0n}
            leverage={leverage}
            tokenAddress={perpConfig.token.address}
            speedUp={speedUp}
            onTransactionSuccess={() =>
              ga4AnalyticEvent(
                'perp',
                'open_position',
                `Position Opened: ${side}, ${leverage}x, ${downPayment} ETH`,
                {token: perpConfig.token, side: side, leverage: leverage, downPayment: downPayment})}
          />
        </GeofenceLayout>
      </div>
    )

  }

  const renderPerpView = () => {
    if (side === 'swap') {
      return renderSwapView();
    }
    const isBlastLong = side === "long" && perpConfig.token.address.toLowerCase() === BLAST_TOKEN.toLowerCase();
    return (
      <>
        <div id="perp-section-input" className="standard-stack">
          <div className="w-full flex flex-row items-center justify-between">
            <div className="flex flex-row items-center gap-2 text-xs tracking-wider font-bold text-neutral-content">
              {
                !isEthMainnet && <>
                  <BsLightningChargeFill />
                  <span>SPEED UP</span>
                  <Switch
                    checked={speedUp}
                    onColor={theme.extend.colors.call}
                    checkedIcon={false}
                    height={16}
                    width={32}
                    handleDiameter={16}
                    uncheckedIcon={false}
                    onChange={e => setSpeedUp(e.valueOf())}
                  />
                </>
              }
            </div>
            <div className="flex items-center">
              {
                speedUp
                  ? <span className="text-xs tracking-wider font-bold p-2 text-neutral-content flex flex-row gap-2 items-center">
                      7.5% SLIPPAGE <FaLock /></span>
                  : <SlippageButton />
              }
            </div>
          </div>
          <TokenInput {...tokenAmount}
                      title="Pay"
                      symbol="ETH"
                      placeholder="0.0"
                      tokenDecimals={18}/>
        </div>
        <div id="perp-section-output" className="standard-stack">
          <TokenOutput
            title={
              <div className="flex flex-row items-center gap-2">
              {capitalizeFirstLetter(side)}
                {
                  data?.outputSizeInETH &&
                  <div className="flex flex-row items-center">
                    {
                      isUsdb ?
                        <span>
                          ${Number(formatUnits(data.outputSize, 18)).toLocaleString([], {maximumFractionDigits: 2})}
                        </span> :
                        <>
                          <FaEthereum size={12} /> {parseFloat(Number(formatUnits(data.outputSizeInETH, 18)).toFixed(3)).toString()}
                        </>
                    }
                  </div>
                }
              </div>
            }
            displayTokenSelect={true}
            value={getOutputSize()}
            isLoading={isLoading}
            placeholder="0.0"
            imageUrl={perpConfig.token.imageUrl}
            symbol={getSymbol()}
            tokenDecimals={(data?.token.decimals || 18) + (showFullNftView ? 1 : 0) * nftFractionDigits} />
        </div>
        <div id="perp-section-leverage" className="standard-stack py-2">
          <div className="text-md font-light">Set Leverage</div>
          <div className="flex flex-row gap-3 items-center justify-between">
            <ReactSlider
              className="flex items-center justify-center w-full flex-grow"
              thumbClassName="customSlider-thumb"
              trackClassName="customSlider-track"
              defaultValue={1.1}
              min={1.1}
              step={0.1}
              max={perpConfig.parameters.maxLeverage}
              renderThumb={
                perpConfig.token.address === '0x58cb30368ceb2d194740b144eab4c2da8a917dcb' ? (
                  (props, stats) =>
                    <div {...props}>
                      <img src="https://wasabi-public.s3.amazonaws.com/zyn_slider.webp" alt="zyn" className="w-[24px] h-[24px] rounded-full" />
                    </div>)
                  : undefined }
              value={leverage}
              onChange={setLeverage}
            />
            <span className="text-2xl min-w-[60px] text-right">{leverage}{perpConfig.token.address === '0x58cb30368ceb2d194740b144eab4c2da8a917dcb' ? <span className="text-sm">mg</span> : 'x'}</span>
          </div>
        </div>
        <div id="perp-section-summary" className="standard-stack">
          {
            perpConfig.token.nftAddress &&
              <div className="flex flex-row items-center gap-2 text-sm">
              <span>
                View as Full NFT
              </span>
              <Switch checked={showFullNftView}
                      onColor={theme.extend.colors.call}
                      checkedIcon={false}
                      height={16}
                      width={32}
                      handleDiameter={16}
                      uncheckedIcon={false}
                      onChange={e => setShowFullNftView(e.valueOf())}/>
            </div>
          }
          {
            (perpConfig.token.address === BLAST_USDC || isBlastLong || (isBlast && side === 'short')) &&
            <SummaryItem<PerpConfig>
              label={<div className="flex flex-row items-end">
                <img src="/static/blast.svg" className="h-6 w-6" alt="blast_earning"/>
                <span className="italic">oost</span>
              </div>}
              className="text-sm text-[#FCFC05]"
              isLoading={false}
              isError={false}
              data={perpConfig}
              tooltip={Tooltips.OPEN_POSITION_BOOST}
            >
              { v => <div className="flex flex-row gap-1 items-center">
                <span className="text-[#FCFC05] text-xl">{leverage * (isBlastLong ? 2 : 1)}x</span>
              </div>}
            </SummaryItem>
          }
          <SummaryItem<PerpQuote>
            label="Entry Price"
            className="text-sm"
            isLoading={isLoading}
            isError={isError}
            data={data}
            disabled={!downPayment}
            tooltip={Tooltips.OPEN_POSITION_ENTRY_PRICE}
          >
            { v =>
              <FractionTokenPrice
                price={v.entryPrice}
                showInUsd={showInUsd}
                showAsFullNFT={showFullNftView}
                tokenType={perpConfig.token.tokenType}
                tokenAddress={perpConfig.token.address} />
            }
          </SummaryItem>
          <SummaryItem<PerpQuote>
            label="Price Impact"
            className="text-sm"
            isLoading={isLoading}
            isError={isError}
            data={data}
            disabled={!downPayment}
            tooltip={Tooltips.OPEN_POSITION_PRICE_IMPACT}
          >
            {
              v =>
                <span className={classNames({
                  "text-white": Math.abs(v.swapQuote.priceImpact) < 0.02, // 5%
                  "text-put": Math.abs(v.swapQuote.priceImpact) >= 0.02 // 5%
                })}>
                {Math.abs(v.swapQuote.priceImpact) < 0.0001 ? "~" : ""} {(v.swapQuote.priceImpact * 100).toFixed(2)} %
              </span>
            }
          </SummaryItem>
          <SummaryItem<PerpQuote>
            label="Liquidation Price"
            className="text-sm"
            isLoading={isLoading}
            isError={isError}
            data={data}
            disabled={!downPayment}
            tooltip={Tooltips.OPEN_POSITION_LIQ_PRICE}
          >
            { v =>
              <FractionTokenPrice
                price={v.liquidationPrice}
                showInUsd={showInUsd}
                showAsFullNFT={showFullNftView}
                tokenType={perpConfig.token.tokenType}
                tokenAddress={perpConfig.token.address}
              /> }
          </SummaryItem>
          <SummaryItem<PerpQuote>
            label="Fees"
            className="text-sm"
            isLoading={isLoading}
            isError={isError}
            data={data}
            disabled={!downPayment}
            tooltip={Tooltips.OPEN_POSITION_FEES}
          >
            {
              v =>
                <span className="flex flex-row items-center text-white">
                <FaEthereum /> {formatEther(v.fee)}
              </span>
            }
          </SummaryItem>
          <SummaryItem<PerpQuote>
            label="Borrow Rate"
            className="text-sm"
            isLoading={isLoading}
            isError={isError}
            data={data}
            disabled={!downPayment}
            tooltip={Tooltips.OPEN_POSITION_BORROW_RATE}
          >
            {
              v => {
                const accuracy = 1_000_000;
                const hourlyInterestAmount = BigInt(v.principal) * BigInt(Math.round(v.hourlyBorrowFee * accuracy)) / BigInt(accuracy);
                const hourlyInterestAmountFormatted = formatUnits(hourlyInterestAmount, v.token.decimals);

                return <div>
                <span className="text-white" id="borrow_rate">
                  {(v.hourlyBorrowFee * 100).toFixed(4)}% / 1h
                </span>
                  {
                    <div className="text-sm text-neutral-content">
                      <ReactTooltip
                        anchorSelect="#borrow_rate"
                        id="borrow_rate_tooltip"
                        place="top"
                        className="z-50 text-lg font-bold"
                        style={{backgroundColor: "#3b485f", color: "#98a2b3"}}
                      >
                        {Number(hourlyInterestAmountFormatted).toLocaleString([], {maximumFractionDigits: 5})} {v.side === 'LONG' ? "ETH" : v.token.symbol} / 1h
                      </ReactTooltip>
                    </div>
                  }
                </div>;
              }
            }
          </SummaryItem>
          <SummaryItem<PerpQuote>
            label="Order Routing"
            className="text-sm"
            tooltip={Tooltips.OPEN_POSITION_ORDER_ROUTING}
            isLoading={isLoading}
            isError={isError}
            data={data}
            disabled={!downPayment}
          >
            {v => <SmartOrderView swapQuote={v.swapQuote} />}
          </SummaryItem>
        </div>
        { isDesktop && renderOpenPositionButton() }
        {
          (address && balance && downPayment && balance.toBigInt() < downPayment) ?
            <ErrorPanel message="Insufficient Balance" /> :
            <>
              { data?.errorMessage && <WarningPanel message={data.errorMessage} /> }
              { isError && <ErrorPanel message="Something went wrong" /> }
            </>
        }
        {
          speedUp ? <WarningPanel className="text-xs p-0" message="Smart routing is disabled, you might experience higher slippage." /> : <div></div>
        }
      </>
    )
  }

  return (
    <div className="h-full flex flex-col justify-start no-scrollbar">
      <PerpSideSelect side={side} onChange={(newSide) => {
          setSide(newSide);
          handleSelection('side', newSide);
      }} />
      <div className="flex-grow py-2 px-4 flex flex-col gap-2 overflow-y-scroll no-scrollbar">
        {renderPerpView()}
      </div>
      { !isDesktop && renderOpenPositionButton() }
    </div>
  )
}
