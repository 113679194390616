import React from "react";
import {FaAngleDown} from "react-icons/fa";
import {Modal, useModal} from "@/components/Modal";
import classNames from "classnames";
import {IoChevronDown} from "react-icons/io5";
import {TokensTableModal} from "@/components/Perps/header/TokensTableModal";

export interface Props {
  imageUrl: string | undefined;
  symbol: string;
  displayTokenSelect: boolean;
  onClick?: Function
  showTokensOnly?: boolean;
}

export const TokenSymbol = (
  { imageUrl, symbol, displayTokenSelect = false, onClick, showTokensOnly }: Props) => {
  const modal = useModal();
  function truncate(str: string, n = 6) {
    return (str.length > n) ? <>{str.slice(0, n - 1)}&hellip;</> : <>{str}</>
  }

  return (
    <div className={classNames("w-max flex flex-row items-center justify-between gap-1 glassborder rounded-full px-2 py-1", {
      "cursor-pointer hover:bg-glass-focus": displayTokenSelect || onClick
    })} onClick={() => {
      onClick && onClick();
      return displayTokenSelect && modal.show();
    }}>
      {
        (imageUrl || symbol === "ETH" || symbol === "WETH") ?
          <img src={symbol === "USDB" ? "https://wasabi-public.s3.amazonaws.com/tokens/usdb.png" : (imageUrl || "https://wasabi-public.s3.amazonaws.com/tokens/weth.png")}
               className="w-7 h-7 rounded-full" alt={symbol} /> :
          <div className="w-7 h-7 rounded-full text-[8px] glassborder flex items-center justify-center">{symbol.substring(0, 3)}</div>
      }
      <span className="text-neutral-content select-none text-base">{truncate(symbol)}</span>
      {
        (displayTokenSelect || onClick) &&
        <>
          <span className="flex items-center">
            <IoChevronDown size={16} className="text-neutral-content" />
          </span>
          <TokensTableModal {...modal} showTokensOnly={showTokensOnly} />
        </>
      }
    </div>
  );
}