import {useGeofence} from "@/hooks/useGeofence";
import {LoadingSpinner} from "@/components/LoadingSpinner";
import {ErrorPanel} from "@/components/ErrorPanel";
import {TERMS_OF_USE_LINK} from "@/util/constants";
import React from "react";
import {twMerge} from "tailwind-merge";

const renderLink = (label: string, href: string) => {
  return <a href={href}
            target="_blank"
            className="text-blue-500">
    {label}
  </a>
}

export interface Props extends React.PropsWithChildren {
  className?: string | undefined;
}

export const GeofenceLayout: React.FC<Props> = ({children, className}) => {
  const geofence = useGeofence();
  return (
    <div className="flex flex-col w-full">
      { geofence.isLoading && <LoadingSpinner /> }
      { geofence.isError && <ErrorPanel message="Something went wrong. Please refresh the page." /> }
      { geofence.isSuccess && <>
        {
          geofence.isAllowed ?
            <>{children}</> :
            <div className={twMerge("standard-frame p-2 md:p-8 text-xl mx-auto", className || '')}>
              Wasabi is unavailable to people or companies who are residents of, or are located, incorporated or have a registered agent in United States of America, United Kingdom and other blocked countries or restricted territories.
              <br/>
              <br/>
              Complete details can be found in our {renderLink("Terms of Use", TERMS_OF_USE_LINK)}.
            </div>
        }
      </>
      }
    </div>
  );
}
