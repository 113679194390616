import React, {useState} from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {BiDownload, BiSolidCopy} from "react-icons/bi";
import {BsTwitter} from "react-icons/bs";
import {fetchBannerShareData, getTradeStatusDownloadImageLink} from "@/api/perpsDataFetcher";
import {PerpTradeShare} from "@/components/Perps/types";
import {useQuery} from "@tanstack/react-query";
import {ErrorPanel} from "@/components/ErrorPanel";
import {Button, ButtonType} from "@/components/Button";
import {IoMdDownload} from "react-icons/io";
import {CHAIN_ID} from "@/util/constants";

interface Props {
  perpTradeShare: PerpTradeShare;
}

export const SharePerpetualPositionTrade = ({perpTradeShare}: Props) => {
  const [copyInfo, setCopyInfo] = useState<string>();

  const tradeStatusImageQuery = useQuery({
    queryKey: ['perp-trade-share', perpTradeShare.id],
    queryFn: async () => await fetchBannerShareData(perpTradeShare.id),
  });

  const getUrl = () => {
    if (tradeStatusImageQuery.data?.data) {
      const bannerData = tradeStatusImageQuery.data.data;
      const chain = CHAIN_ID === 1 ? 'perps' : 'blast';
      const bannerCode = bannerData.fileName.replace(".png", "");
      return `https://${chain}.wasabi.xyz/perps/${bannerCode}?p=${bannerData.code}&side=${perpTradeShare.side}&token=${perpTradeShare.token.address}`;
    }
    return '';
  }

  const getImgUrl = () => {
    if (tradeStatusImageQuery.data?.data) {
      return `https://wasabi-public.s3.amazonaws.com/trades/${tradeStatusImageQuery.data.data.fileName}`;
    }
    return '';
  }

  const getTweetText = () => {
    const url = getUrl();
    if (url.length === 0) {
      return '';
    }
    let side = perpTradeShare.side;
    let tokenName = perpTradeShare.token.name;
    if (perpTradeShare.token.symbol === "USDB") {
      side = side === "LONG" ? "SHORT" : "LONG";
      tokenName = "ETH";
    } else if (perpTradeShare.token.twitterHandle) {
      tokenName = perpTradeShare.token.twitterHandle;
    }
    return encodeURIComponent(`${side === "LONG" ? "Longed" : "Shorted"} ${tokenName} on @wasabi_protocol\n\n${getUrl()}`)
  }

  if (tradeStatusImageQuery.isLoading) {
    return (
      <div className="p-2 standard-stack !gap-4 items-center max-w-[600px] relative">
        <span className="w-full flex flex-row gap-2 items-center capitalize">
          <div className="rounded-md bg-slate-600 animate-pulse min-h-[280px] min-w-[580px] "></div>
        </span>
        <p className="text-md">Copy & Share</p>
        <div className="text-md flex items-center w-full">
          <div className=" animate-pulse min-h-[55px]  w-full bg-slate-600 rounded-md p-4  "></div>
        </div>
      </div>
    );
  }
  if (tradeStatusImageQuery.isError) {
    return (
      <div className="p-2 standard-stack !gap-4 items-center max-w-[900px] relative">
        <ErrorPanel message="Something went wrong. Please try again or contact us."/>
      </div>
    );
  }

  const handleCopyText = () => {
    navigator.clipboard.writeText(getUrl()).then(() => {
      setCopyInfo("URL copied to clipboard");
      setTimeout(() => {
        setCopyInfo("");
      }, 2000);
    });
  };

  return (
    <div className=" p-2 standard-stack !gap-4 items-center max-w-[900px] relative">
      <span className="w-full flex flex-row gap-2 items-center capitalize">
        {tradeStatusImageQuery.data && (
          <img alt="trade_image"
               src={getImgUrl()}
               className="standard-frame w-full max-h-[310px] max-w-[600px] rounded-md"
          />
        )}
      </span>
      <p className="text-lg text-white">Share Position, Invite Friends and Earn Points</p>
      <div className="responsive-flex items-center justify-center gap-2 w-full">
        <Button onClick={handleCopyText} buttonType={ButtonType.SECONDARY} className="min-w-[130px]">
          <BiSolidCopy/> Copy
        </Button>
        <a href={getTradeStatusDownloadImageLink(tradeStatusImageQuery.data?.data?.fileName || '')}
           target="_blank"
           download={`wasabi_perps_trade_${perpTradeShare.id}`}>
          <Button onClick={handleCopyText} buttonType={ButtonType.SECONDARY} className="min-w-[130px]">
            <IoMdDownload/> Download
          </Button>
        </a>
        <a href={"https://x.com/intent/post?text=" + getTweetText() + "&related=wasabi_protocol"}
           target="_blank">
          <Button onClick={handleCopyText} buttonType={ButtonType.PRIMARY} className="min-w-[130px]">
            <BsTwitter/> Share
          </Button>
        </a>
      </div>
      {copyInfo && <span className="text-sm text-green-500">{copyInfo}</span>}
    </div>
  );
};
