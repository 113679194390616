import {useInfiniteQuery} from "@tanstack/react-query";
import {fetchPositionTredeHistory} from "@/api/perpsDataFetcher";
import {numberSort, Table, TableColumn} from "@/components/Table";
import {PerpTradeShare, Position, PositionTradeHistory} from "@/components/Perps/types";
import {EthPrice} from "@/components/Perps/EthPrice";
import {EthValue} from "@/components/Perps/EthValue";
import React, {useState} from "react";
import {Tooltip as ReactTooltip} from "react-tooltip";
import classNames from "classnames";
import {EtherscanTransactionDisplay} from "@/components/Account/EtherscanTransactionDisplay";
import {PositionHeader} from "@/components/Perps/positions/PositionHeader";
import {Modal, useModal} from "@/components/Modal";
import {SharePerpetualPositionTrade} from "@/components/Sharing/SharePerpetualPositionTrade";
import {LoadingSpinner} from "@/components/LoadingSpinner";
import {MdIosShare} from "react-icons/md";
import moment from "moment";
import {useCurrentUser} from "@/hooks/useCurrentUser";
import {FractionTokenPrice} from "@/components/Perps/FractionTokenPrice";
import {useEthPrice} from "@/contexts/EthPriceContext";
import * as Tooltips from "@/util/tooltips";

export const PerpPositionTradeHistoryTable = ({onTokenClicked, className}: { onTokenClicked: (token: string) => any, className?: string | undefined }) => {
    const {address} = useCurrentUser();
    const {showInUsd} = useEthPrice();
    
    const query = useInfiniteQuery({
        queryKey: ['position_history', address],
        queryFn: async ({ pageParam = "0" }) => await fetchPositionTredeHistory(address!, pageParam),
        initialPageParam: "0",
        getNextPageParam: (lastPage) => lastPage.nextPageToken,
        enabled: !!address,
        refetchInterval: 15 * 1000, // 15 seconds
    });

    const [perpTradeShareData, setPerpTradeShareData] = useState<PerpTradeShare>();
    const handleShareClick = (positionHistory: PositionTradeHistory) => {
        setPerpTradeShareData({
            id: positionHistory.position.id,
            action: positionHistory.action,
            side: positionHistory.position.side,
            token: positionHistory.token
        });
        sharePerpetualPositionModal.show();
    };

    const sharePerpetualPositionModal = useModal();

    const columns: TableColumn<PositionTradeHistory>[] = [
        {
            id: "action",
            label: "Action",
            valueRenderer: d => <div>
                <div>{d.action}</div>
                <div className="text-xs text-neutral-content">ID: {d.position.id}</div>
            </div>,
            span: 2,
        },
        {
            id: "position",
            label: "Position",
            valueRenderer: d => <PositionHeader
                position={d.position}
                token={d.token}
                onTokenClicked={t => onTokenClicked(t.symbol)}
            />,
            align: "left",
            span: 2,
        },
        {
            id: "price",
            label: "Entry/Exit Price",
            valueRenderer: d =>
              <FractionTokenPrice
                price={d.price}
                tokenAddress={d.token.address}
                tokenType={d.token.tokenType}
                iconSize={12}
                showInUsd={showInUsd}
                exponentClassName="flex flex-row items-center text-xs text-neutral-content" />,
            tooltip: Tooltips.TRADE_HISTORY_ENTRY_EXIT_PRICE,
            align: "left",
            span: 2,
        },
        {
            id: "amount",
            label: "Paid/Received",
            valueRenderer: d =>
                <EthValue
                    showPlus={true}
                    value={BigInt(d.amount) * (d.action !== "OPEN" ? 1n : -1n)}
                    className={classNames({
                        "flex-col items-end": d.action !== "OPEN"
                    })}
                />,
            tooltip: Tooltips.TRADE_HISTORY_PAID_RECEIVED,
            align: "left",
            span: 2,
        },
        {
            id: "pnl",
            label: "PnL",
            valueRenderer: d =>
                d.action === "OPEN"
                    ? null
                    : (
                        <EthValue
                            showPlus={true}
                            valueForPercent={BigInt(d.amount) + BigInt(d.fees)}
                            value={BigInt(d.amount) + BigInt(d.fees) - BigInt(d.position.downPaymentRaw)}
                            original={BigInt(d.position.downPaymentRaw)}
                            className="w-full flex-col items-end"
                        />
                    ),
            tooltip: Tooltips.TRADE_HISTORY_PNL,
            align: "right",
            span: 1,
        },
        {
            id: "timestamp",
            label: "Timestamp",
            sort: numberSort((t) => t.timestamp),
            valueRenderer: (t) => {
                const id = `perp_timestamp_${t.position.id}_${t.action}`;
                return (
                    <>
                    <EtherscanTransactionDisplay
                        hash={t.transactionHash}
                        label={(
                            <span id={id} className="text-neutral-content underline decoration-dashed underline-offset-4">
                                <span>{moment.unix(t.timestamp).fromNow()}</span>
                                <ReactTooltip
                                    anchorSelect={`#${id}`}
                                    id={"tooltip_" + (id || "expiresIn_tooltip")}
                                    place="top"
                                    noArrow
                                    className="z-50"
                                    content={new Date(t.timestamp * 1000).toLocaleString([], {timeZoneName: "short"})}
                                    style={{backgroundColor: "#3b485f", color: "#98a2b3"}}
                                />
                      </span>)}/>
                    </>);
            },
            align: "right",
            span: 2,
        },
        {
            id: 'share',
            label: '',
            valueRenderer: d => {
                const id = `history_perp_share_${d.position.id}_${d.action}`;
                return (
                  <div
                    id={id}
                    className="hover:scale-110 text-neutral-content hover:cursor-pointer hover:text-white"
                    onClick={() => handleShareClick(d)}>

                      <MdIosShare size={22}/>
                      <ReactTooltip
                        anchorSelect={`#${id}`}
                        id={`tooltip_${id}`}
                        place="top-start"
                        noArrow
                        className="z-50"
                        content="Share Your Position"
                        style={{backgroundColor: "#3b485f", color: "#98a2b3"}}
                      />
                  </div>
                );
            },
            align: "right"
        }
    ];

    const handleFetchNextPage = () => {
        if (!query.isFetchingNextPage) {
            query.fetchNextPage();
        }
    };

    const actionContent = query.hasNextPage && (
        <div className="h-10 flex items-center justify-center hover:bg-glass cursor-pointer text-neutral-content">
            {query.isFetchingNextPage ? <LoadingSpinner /> : 'Load More'}
        </div>
    );

    return (
        <>
            <Table
                id="perp_position_history"
                className={className}
                columns={columns}
                data={query.data?.pages.flatMap((p) => p.items)}
                actionPressed={handleFetchNextPage}
                actionContent={actionContent}
                isLoading={query.isLoading}
                emptyText="No history"
            />
            {perpTradeShareData && (
                <Modal {...sharePerpetualPositionModal}>
                    <SharePerpetualPositionTrade perpTradeShare={perpTradeShareData}/>
                </Modal>
            )}
        </>
    )
}
