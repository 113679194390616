import {PerpPositionStatus, Position} from "@/components/Perps/types";
import React, {useState} from "react";
import {PositionHeader} from "@/components/Perps/positions/PositionHeader";
import {PnLDisplay} from "@/components/Perps/positions/PnLDisplay";
import {MdIosShare} from "react-icons/md";
import {Tooltip as ReactTooltip} from "react-tooltip";
import {Button, ButtonType} from "@/components/Button";
import {FaChevronDown, FaChevronUp} from "react-icons/fa";
import {SummaryItem} from "@/components/Perps/SummaryItem";
import {FractionTokenPrice} from "@/components/Perps/FractionTokenPrice";
import {useEthPrice} from "@/contexts/EthPriceContext";
import {SizeView} from "@/components/Perps/positions/SizeView";

interface Props {
  positionStatus: PerpPositionStatus;
  onTokenClicked: (token: string) => any;
  handleShareClick: (position: PerpPositionStatus) => any;
  setSelectedPosition: (position: PerpPositionStatus) => any;
}

export const MobilePosition = ({positionStatus, onTokenClicked, handleShareClick, setSelectedPosition}: Props) => {
  const {showInUsd} = useEthPrice();
  const [showDetails, setShowDetails] = useState(false);

  const position = positionStatus.position;
  const token = positionStatus.token;

  const id = `perp_share_${position.id}`;
  return (
    <div className="grid grid-cols-2 gap-4">
      <PositionHeader position={position}
                      token={token}
                      imageSize={14}
                      onTokenClicked={t => onTokenClicked(t.symbol)}/>
      <div className="flex flex-col items-end">
        <span className="text-neutral-content text-xs">PnL</span>
        <PnLDisplay positionStatus={positionStatus} className="!flex-col !items-end"/>
      </div>
      <div id={id}
           className="hover:scale-110 text-neutral-content hover:cursor-pointer hover:text-white flex flex-row items-center justify-start gap-2"
           onClick={() => handleShareClick(positionStatus)}>
        <MdIosShare size={22}/> Share
        <ReactTooltip
          anchorSelect={`#${id}`}
          id={`tooltip_${id}`}
          place="left"
          noArrow
          className="z-50"
          content="Share Your Position"
          style={{backgroundColor: "#3b485f", color: "#98a2b3"}}
        />
      </div>
      <div className="flex flex-row items-center justify-end w-full">
        <Button
          buttonType={ButtonType.NEUTRAL}
          onClick={() => setSelectedPosition(positionStatus)}
          className="!py-1 !text-lg !font-normal !capitalize"
        >
          Close
        </Button>
      </div>
      <div className="col-span-2 standard-stack">
        <div className="w-full text-neutral-content flex flex-row items-center justify-center gap-2 text-sm"
             onClick={() => setShowDetails(s => !s)}>
          {showDetails ? <FaChevronUp /> : <FaChevronDown />}
          {showDetails ? "Hide Details" : "Show Details"}
        </div>
        {
          showDetails &&
          <>
            <SummaryItem<PerpPositionStatus>
              label="Size"
              className="text-sm"
              isLoading={false}
              isError={positionStatus.hasError}
              data={positionStatus}
            >
              { v => <SizeView positionStatus={v} /> }
            </SummaryItem>
            <SummaryItem<PerpPositionStatus>
              label="Entry Price"
              className="text-sm"
              isLoading={false}
              isError={positionStatus.hasError}
              data={positionStatus}
            >
              { v =>
                <FractionTokenPrice
                  price={v.position.entryPrice}
                  showInUsd={showInUsd}
                  tokenType={v.token.tokenType}
                  tokenAddress={v.token.address} />
              }
            </SummaryItem>
            <SummaryItem<PerpPositionStatus>
              label="Index Price"
              className="text-sm"
              isLoading={false}
              isError={positionStatus.hasError}
              data={positionStatus}
            >
              { v =>
                <FractionTokenPrice
                  price={v.markPrice}
                  showInUsd={showInUsd}
                  tokenType={v.token.tokenType}
                  tokenAddress={v.token.address} />
              }
            </SummaryItem>
            <SummaryItem<PerpPositionStatus>
              label="Liquidation Price"
              className="text-sm"
              isLoading={false}
              isError={positionStatus.hasError}
              data={positionStatus}
            >
              { v =>
                <FractionTokenPrice
                  price={v.liquidationPrice}
                  showInUsd={showInUsd}
                  tokenType={v.token.tokenType}
                  tokenAddress={v.token.address} />
              }
            </SummaryItem>
          </>
        }
      </div>
    </div>
  )
}