import {formatUnits, parseUnits } from "viem";
import {TokenSymbol} from "@/components/Perps/TokenSymbol";
import React from "react";
import {Token, PerpToken} from "@/components/Perps/types";
import {Modal, useModal} from "@/components/Modal";
import {ETH_TOKEN, WETH_TOKEN} from "@/util/chainConstants";
import {TokensTableModal} from "@/components/Perps/header/TokensTableModal";

export interface Props {
  label: string;
  value: bigint | undefined;
  onChange: (value: bigint | undefined) => void;
  token: Token;
  isLoading: boolean;
  onTokenSelect: (token: Token) => void;
  balance: bigint;
  usdPrice: number;
}

export const SwapTokenInput = ({ label, value, onChange, token, isLoading, onTokenSelect, balance, usdPrice }: Props) => {
  const modal = useModal();
  const baseTokens = [ETH_TOKEN, WETH_TOKEN];

  const getUsdValue = () => {
    if (value && usdPrice) {
      return (Number(formatUnits(value, token.decimals)) * usdPrice).toLocaleString(['en-US'], {style: 'currency', currency: 'USD'});
    }
    return "0";
  }

  return (
    <div className="standard-frame flex flex-row justify-between items-center py-5 px-2 text-xl w-full">
      <div className="flex flex-col gap-1 max-w-[200px]">
        <span className="text-neutral-content text-xs">{label}</span>
        {
          isLoading ?
            <div className="rounded-md w-[180px] h-[32px] bg-slate-600 animate-pulse flex-grow"/> :
            <input
              type="number"
              className="unstyled-input text-2xl text-white truncate w-[180px]"
              placeholder="0"
              value={value ? formatUnits(value, token.decimals) : undefined}
              onChange={(e) => {
                const value = e.target.value;
                if (value) {
                  onChange(parseUnits(value, token.decimals));
                } else {
                  onChange(undefined);
                }
              }}
            />
        }
        { value && usdPrice > 0 && <span className="text-neutral-content text-xs">{getUsdValue()}</span>}
        <div></div>
      </div>
      <div className="flex flex-col items-end gap-2">
        <div></div>
        <TokenSymbol
          symbol={token.symbol}
          imageUrl={isPerpToken(token) ? token.imageUrl : undefined}
          displayTokenSelect={false}
          onClick={modal.show}
          showTokensOnly={true}
        />
        <span className="text-xs font-light text-neutral-content cursor-pointer">
            Balance: {Number(formatUnits(balance, token.decimals)).toLocaleString([], {maximumFractionDigits: 2})} {label === "You pay" &&
          <span className="text-blue-500 hover:text-blue-300 hover:underline" onClick={() => onChange(balance)}>Max</span>}
        </span>
      </div>

      <TokensTableModal {...modal}
                        showTokensOnly={true}
                        displayChainSelector={false}
                        columnsToExclude={["openInterest"]}
                        onSelected={token => {
                          onTokenSelect(token);
                          modal.hide();
                        }}>
        <div className="flex flex-row items-center gap-2 pb-2 px-2">
          {
            baseTokens.map(token => (
              <div
                className="standard-frame !rounded-full flex flex-row gap-1 items-center px-3 py-1 hover:bg-glass-focus cursor-pointer"
                onClick={() => {
                  onTokenSelect(token);
                  modal.hide();
                }}
                key={`base_token_${token.symbol}`}>
                <img src="https://wasabi-public.s3.amazonaws.com/tokens/weth.png"
                     className="w-6 h-6 rounded-full" alt={`base_token_${token.symbol}`}/>
                <span>{token.symbol}</span>
              </div>
            ))
          }
        </div>
        <hr className="border-neutral-content/30 w-full"/>
      </TokensTableModal>
    </div>
  )
}

function isPerpToken(token: Token): token is PerpToken {
  return 'imageUrl' in token;
}