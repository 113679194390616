import {Props as TokensTableProps, TokensTable} from "@/components/Perps/header/TokensTable";
import {Modal, UseModalProps} from "@/components/Modal";
import React from "react";

export interface Props extends TokensTableProps, UseModalProps {
  children?: React.ReactNode;
}

export const TokensTableModal = (props: Props) => {
  return (
    <Modal {...props}>
      {props.children}
      <TokensTable {...props} className="md:min-w-[800px] md:max-h-[600px] overflow-hidden pb-safe h-full" />
    </Modal>
)
}