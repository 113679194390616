import { useState } from "react";
import { parseUnits } from "viem";

export const useTokenAmount = (tokenDecimals: number) => {
  const [inputValue, setInputValue] = useState<string>();

  return {
    inputValue,
    setInputValue,
    value: inputValue ? parseUnits(inputValue, tokenDecimals) : undefined
  };
}