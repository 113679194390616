import {useQuery} from "@tanstack/react-query";
import {fetchUserReferralCode, fetchUserStats} from "@/api/perpsDataFetcher";
import React from "react";
import {AddressDisplay} from "@/components/Account/AddressDisplay";
import {ConnectWallet} from "@/components/ConnectWallet";
import {SummaryItem} from "@/components/Perps/SummaryItem";
import {UserStats} from "@/components/Perps/types";
import {EthValue} from "@/components/Perps/EthValue";
import {useEthPrice} from "@/contexts/EthPriceContext";
import {useRouter} from "next/router";
import {SplitLabelValue} from "@/components/SplitLabelValue";
import {useCurrentUser} from "@/hooks/useCurrentUser";
import {useHasMounted} from "@/hooks/useHasMounted";
import {BiSolidCopy} from "react-icons/bi";
import {isBlast} from "@/util/constants";
import {prettifyNumber} from "@/util/converters";
import {Badge} from "@/components/BadgeContainer";
import {OptionalDataResponse} from "@/types/types";
import {useIsDesktop} from "@/hooks/useIsDesktop";
import * as Tooltips from "@/util/tooltips";

export const UserStatsView = () => {
  const isDesktop = useIsDesktop();
  const {address} = useCurrentUser();
  const {ethPrice} = useEthPrice();
  const router = useRouter();
  const hasMounted = useHasMounted();

  const {isLoading, data: stats, isError} = useQuery({
    queryKey: ["user-stats", address],
    queryFn: async () => await fetchUserStats(address!),
    enabled: !!address && router.isReady,
    refetchInterval: 60 * 1000,
  });

  const refCodeQuery = useQuery({
    queryKey: ["user-referral-code", address],
    queryFn: async () => await fetchUserReferralCode(address!),
    enabled: !!address && router.isReady,
  });

  const handleCopyText = () => {
    if (refCodeQuery.data?.data) {
      const referralCode = `${isBlast ? 'blast' : 'perps'}.wasabi.xyz/p/${refCodeQuery.data.data.valueOf()}`;
      navigator.clipboard.writeText(referralCode);
    }
  };

  if (!hasMounted || !address) {
    return <ConnectWallet/>;
  }

  return (
    <div className="h-full grid grid-cols-1 md:grid-cols-2 gap-4 w-full divide-y divide-x-0 md:divide-y-0 md:divide-x divide-neutral-content/20">
      {
        hasMounted && address &&
        <>
          <div className="h-full standard-stack !gap-4 p-4">
            <div className="w-full responsive-flex gap-2">
              <SummaryItem<UserStats>
                label="Account"
                className="text-sm !gap-2 md:w-1/2"
                isRow={true}
                isLoading={isLoading}
                data={stats}
                isError={isError}
              >
                {v =>
                  <span className="text-white">
                            <AddressDisplay
                              short={true}
                              className="!font-normal text-white"
                              address={v.address}
                              displaySelf={false}
                              showAvatar={true}
                              useBlockie={true}
                            />
                          </span>}
              </SummaryItem>
              <SummaryItem<OptionalDataResponse<String>>
                label="Referral Link"
                className="text-sm !gap-2 md:w-1/2"
                isRow={true}
                isLoading={refCodeQuery.isLoading}
                isError={refCodeQuery.isError}
                data={refCodeQuery.data}
                tooltip={Tooltips.POINTS_REFERRAL_LINK}
              >
                {
                  v => <div
                    className="flex flex-row items-center gap-2 hover:cursor-pointer hover:text-neutral-content pt-2"
                    onClick={handleCopyText}>
                            <span
                              className="text-neutral-content text-xs">{isBlast ? 'blast' : 'perps'}.wasabi.xyz/p/<span
                              className="text-white text-sm">{v.data}</span></span>
                    <BiSolidCopy/>
                  </div>
                }
              </SummaryItem>
            </div>
            <hr className="border-neutral-content/50"/>
            <div className="w-full grid grid-cols-2 gap-2">
              <SummaryItem<UserStats>
                label="Trading Points"
                className="text-sm"
                isRow={true}
                isLoading={isLoading}
                data={stats}
                isError={isError}>
                {
                  v => <div className="flex items-center">
                    <img src="/static/wasabi-icon-180px.png" alt="wasabi" className="w-8 h-8 rounded-full"/>
                    <p className="text-white text-2xl">{v.tradePoints.toLocaleString(undefined, {maximumFractionDigits: 0})}</p>
                  </div>
                }
              </SummaryItem>
              <SummaryItem<UserStats>
                label="LP Points"
                className="text-sm"
                isRow={true}
                isLoading={isLoading}
                data={stats}
                isError={isError}>
                {
                  v => <div className="flex items-center">
                    <img src="/static/wasabi-icon-180px.png" alt="wasabi" className="w-8 h-8 rounded-full"/>
                    <p className="text-white text-2xl">{((v.leaderboardItem?.lPPoints || 0) * 100).toLocaleString(undefined, {maximumFractionDigits: 0})}</p>
                  </div>
                }
              </SummaryItem>
            </div>
            {
              isBlast &&
              <>
                <hr className="border-neutral-content/50"/>
                <div className="w-full grid grid-cols-2 gap-2">
                  <SummaryItem<UserStats>
                    label="Blast Points"
                    className="text-sm"
                    isRow={true}
                    isLoading={isLoading}
                    data={stats}
                    isError={isError}>
                    {
                      v => <div className="flex items-center gap-2">
                        <img src="/static/blast.svg" alt="blast_earning" className="w-6 h-6 rounded-full"/>
                        <p className="text-white text-2xl">{prettifyNumber(v.leaderboardItem?.blastPoints || 0)}</p>
                      </div>
                    }
                  </SummaryItem>
                  <SummaryItem<UserStats>
                    label="Blast Gold"
                    className="text-sm"
                    isRow={true}
                    isLoading={isLoading}
                    data={stats}
                    isError={isError}>
                    {
                      v => <div className="flex items-center gap-2">
                        <img src="/static/blast_gold.png" alt="gold" className="w-6 h-6 rounded-full"/>
                        <p className="text-white text-2xl">{prettifyNumber((stats?.leaderboardItem?.blastGold || 0) + (stats?.leaderboardItem?.claimableBlastGold || 0))}</p>
                        {
                          (v.leaderboardItem?.claimableBlastGold || 0) > 0 &&
                          <a href="/gold" className="text-[#FCFC05] hover:underline">
                            Claim Now
                          </a>
                        }
                      </div>
                    }
                  </SummaryItem>
                </div>
              </>
            }
          </div>
          <div className="h-full standard-stack p-4">
            <SummaryItem<UserStats>
              label="PnL"
              className="text-sm text-white"
              isLoading={isLoading}
              isError={isError}
              data={stats}
              tooltip={Tooltips.OVERVIEW_PNL}
            >
              {stats =>
                <EthValue value={BigInt(stats.totalPnL)}
                          valueForPercent={BigInt(stats.totalPnL) + BigInt(stats.totalDownPayment)}
                          original={BigInt(stats.totalDownPayment)}
                          ethPrice={ethPrice}/>}
            </SummaryItem>
            <hr className="border-neutral-content/50 w-full"/>
            <SummaryItem<UserStats>
              label="Volume"
              className="text-sm text-white"
              isLoading={isLoading}
              isError={isError}
              data={stats}
              tooltip={Tooltips.OVERVIEW_VOLUME}>
              {stats => <EthValue value={stats.totalVolume} ethPrice={ethPrice}/>}
            </SummaryItem>
            <hr className="border-neutral-content/50 w-full"/>
            {/*<div className="w-full gap-2 mt-8">*/}
            {/*  <SplitLabelValue label="Badges" className="text-sm" isRow={true}>*/}
            {/*    <div className="flex flex-wrap items-center gap-2">*/}
            {/*      <Badge tooltip="coming soon"/>*/}
            {/*      <Badge tooltip="coming soon"/>*/}
            {/*      {isDesktop && <Badge tooltip="coming soon"/>}*/}
            {/*      {isDesktop && <Badge tooltip="coming soon"/>}*/}
            {/*      {isDesktop && <Badge tooltip="coming soon"/>}*/}
            {/*    </div>*/}
            {/*  </SplitLabelValue>*/}
            {/*</div>*/}
          </div>
        </>
      }
    </div>
  );
}
