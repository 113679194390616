import {NextSeo} from "next-seo";
import {NextPageWithLayout} from "./_app";
import React, {ReactElement, useEffect, useState} from "react";
import {PerpTokenView} from "@/components/Perps/header/PerpTokenView";
import {OpenPositionView} from "@/components/Perps/openPosition/OpenPositionView";
import {useQuery} from "@tanstack/react-query";
import {fetchPerpConfig} from "@/api/perpsDataFetcher";
import {PerpConfig} from "@/components/Perps/types";
import {useRouter} from "next/router";
import {useQueryParam, useStringQueryParam} from "@/hooks/useQueryParam";
import {DropdownOption} from "@/components/Dropdown";
import {ga4AnalyticEvent, ga4AnalyticPages} from "@/util/analytics";
import {GeofenceLayout} from "@/layouts/GeofenceLayout";
import {UserStatsView} from "@/components/Perps/user/UserStatsView";
import {useUserSelection} from "@/contexts/UserSelectionContext";
import {PrimaryLayout2} from "@/layouts/Primary2Layout";
import {MdDragHandle} from "react-icons/md";
import classNames from "classnames";
import {Panel, PanelGroup, PanelResizeHandle} from "react-resizable-panels";
import {Tabs} from "@/components/Tabs";
import {PerpPositionTable} from "@/components/Perps/positions/PerpPositionTable";
import {
  PerpPositionTradeHistoryTable
} from "@/components/Perps/positions/PerpPositionTradeHistoryTable";
import {DexToolsChart} from "@/components/Perps/DexToolsChart";
import {defaultToken, DISCORD_LINK, getBaseUrl, isBlast, TWITTER_LINK} from "@/util/constants";
import {DefinedChart} from "@/components/Perps/DefinedChart";
import {BsLightningChargeFill, BsStack} from "react-icons/bs";
import {IoMdBook, IoMdWallet} from "react-icons/io";
import {IoStatsChart} from "react-icons/io5";
import Switch from "react-switch";
import {theme} from "../tailwind.config";
import {FaChartLine, FaUser} from "react-icons/fa";
import {TradeActivity} from "@/components/Perps/positions/TradeActivity";
import {FiActivity} from "react-icons/fi";
import {PerpHeader} from "@/components/Perps/header/PerpHeader";
import {useHasMounted} from "@/hooks/useHasMounted";
import {useIsDesktop} from "@/hooks/useIsDesktop";
import {useCustomHeaderContent} from "@/contexts/CustomHeaderContentContext";

const tabs: DropdownOption<string>[] = [
  { label: "Active Positions", shortLabel: "Positions", value: "active", icon: <BsStack /> },
  { label: "Trade History", shortLabel: "History", value: "history", icon: <IoMdBook /> },
  { label: "Profile", value: "profile", icon: <IoStatsChart /> },
];


const mobileTabs: DropdownOption<string>[] = [
  { label: "Trade", value: "trade", icon: <BsLightningChargeFill /> },
  { label: "Positions", value: "positions", icon: <IoMdWallet /> },
  { label: "Chart", value: "chart", icon: <FaChartLine /> },
  { label: "Activity", value: "activity", icon: <FiActivity /> },
  { label: "Profile", value: "profile", icon: <FaUser /> }
];

const Page: NextPageWithLayout = () => {
  const hasMounted = useHasMounted();
  const isLargeScreen = useIsDesktop();
  const router = useRouter();
  const { userSelections, handleSelection } = useUserSelection();
  const [token, setToken] = useStringQueryParam('token', userSelections.token || defaultToken);
  const [perpConfig, setPerpConfig] = useState<PerpConfig | undefined>();
  const [tab, setTab] = useQueryParam<string>('t', tabs[0].value, v => v);
  const [mobileTab, setMobileTab] = useState(mobileTabs[0].value);

  const {data,isLoading} = useQuery({
    queryKey: ["perp_config", token],
    queryFn: async () => {
      try {
        return await fetchPerpConfig(token as string);
      } catch (e) {
        return await fetchPerpConfig(defaultToken);
      }
    },
    enabled: router.isReady,
  });

  useEffect(() => {
    if (data) {
      setPerpConfig(data);
    }
  }, [data]);

  useEffect(() => {
    if (perpConfig && perpConfig.token.symbol !== token) {
      ga4AnalyticEvent(
        'perp',
        'token_select',
        `Selected Token: ${perpConfig.token.symbol}`,
        {
          address: perpConfig.token.address,
          symbol: perpConfig.token.symbol,
          name: perpConfig.token.name,
        }
      )
      setToken(perpConfig.token.symbol);
    }
    if (perpConfig?.token.symbol) {
      handleSelection('token', perpConfig.token.symbol);
    }
  }, [perpConfig]);

  useEffect(() => {
    ga4AnalyticPages(router.pathname, "Perps");
  }, []);

  const handleSideChange = (side: string) => {
    ga4AnalyticEvent(
      'perp',
      'side_select', `Selected Side: ${side}`,
      {side})
    setTab(side);
  };

  const renderPortfolio = () => {
    return (
      <div className="flex flex-col h-full w-full divide-y divide-neutral-content/20">
        <Tabs<string>
          options={tabs}
          value={tab}
          onChange={handleSideChange}
          className="w-full h-[32px] rounded-none border-0" />
        <div className="flex-grow w-full overflow-y-hidden overflow-x-scroll no-scrollbar">
          {
            tab === "active" &&
            <PerpPositionTable
              onTokenClicked={t => t !== token && setToken(t)}/>
          }
          {
            tab === "history" &&
            <PerpPositionTradeHistoryTable
              className={classNames('h-full border-none rounded-none no-scrollbar !bg-transparent', {
                'min-w-[1000px]': !isLargeScreen,
              })}
              onTokenClicked={t => t !== token && setToken(t)}/>
          }
          {
            tab === "profile" &&
            <div className="h-full">
              <UserStatsView />
            </div>
          }
        </div>
      </div>
    )
  }
  const renderChart = (perpConfig: PerpConfig) => {
    // if (isBlast) {
      return (
        <DefinedChart
          key={`${perpConfig.token.pairAddress}-${perpConfig.token.address}-${userSelections.showAsUsd}-${userSelections.showChart}-${userSelections.showTxTable}-${userSelections.showSidebar}`}
          address={perpConfig.token.address}
          pairAddress={perpConfig.token.pairAddress}
        />
      );
  //   }
  //   return (
  //     <DexToolsChart
  //       key={`${perpConfig.token.pairAddress}-${perpConfig.token.address}-${userSelections.showAsUsd}`}
  //       width="100%"
  //       height="100%"
  //       pairAddress={perpConfig.token.pairAddress} />
  //   );
  }

  const renderSwitch = (element: string, key: string, value: boolean | undefined) => {
    return (
      <div className="flex flex-row gap-1 items-center justify-center px-2">
        <span className="!cursor-pointer hover:text-white"
              onClick={() => handleSelection(key, !value)}>
          {element}
        </span>
        <Switch checked={value || false}
                onColor={theme.extend.colors.call}
                checkedIcon={false}
                height={12}
                width={24}
                handleDiameter={10}
                uncheckedIcon={false}
                onChange={e => handleSelection(key, e.valueOf())}/>
      </div>
    );
  }

  const renderHeader = () => {
    return (<div className="flex flex-col lg:flex-row gap-4 justify-between w-full px-4 md:px-2 py-1">
      {
        (isLoading || !perpConfig || !hasMounted) ?
          <div className="rounded-md p-6 w-full h-full bg-slate-600 animate-pulse"/> :
          <PerpHeader perpConfig={perpConfig} onChange={setPerpConfig}/>
      }
    </div>);
  }

  return (
    <div className="w-full h-full flex flex-col no-scrollbar overflow-hidden divide-y divide-neutral-content/20">
      <NextSeo openGraph={{
        url: getBaseUrl(),
        title: "Wasabi",
        description: "The Decentralized Leverage Protocol",
      }}
      />
      {
        isLargeScreen &&
        <div className="h-full flex-grow flex flex-row divide-x divide-neutral-content/20">
          <div className="lg:flex-grow flex flex-col divide-y divide-neutral-content/20">
            { renderHeader() }
            {
              (isLoading || !perpConfig) ?
                <div className="w-full h-full p-2">
                  <div className="flex-grow h-full rounded-md bg-slate-600 animate-pulse"/>
                </div> :
                <PanelGroup direction="vertical" autoSaveId="perp_panels">
                  <Panel maxSize={100}>
                    <div className="flex flex-row h-full divide-x divide-neutral-content/20">
                      { renderChart(perpConfig) }
                      {userSelections.showTrades && <TradeActivity token={perpConfig.token}/>}
                    </div>
                  </Panel>
                  <div className="w-full relative flex justify-end items-center bg-glass h-[24px]">
                    <PanelResizeHandle className="absolute m-auto left-0 right-0 w-full h-full flex flex-row items-center justify-center text-neutral-content hover:text-white">
                      <MdDragHandle size={22}/>
                    </PanelResizeHandle>
                    <div className="flex flex-row items-center justify-evenly text-xs text-neutral-content p-[4px] min-w-[225] z-50 divide-x divide-neutral-content/20">
                      {renderSwitch('Chart', 'showChart', userSelections.showChart)}
                      {renderSwitch('Trades', 'showTrades', userSelections.showTrades)}
                      {renderSwitch('Info', 'showSidebar', userSelections.showSidebar)}
                      {renderSwitch('Txns', 'showTxTable', userSelections.showTxTable)}
                    </div>
                  </div>
                  <Panel maxSize={100}>
                    { renderPortfolio() }
                  </Panel>
                </PanelGroup>
            }
          </div>
          <div className="flex-1 sm:w-full md:w-full lg:min-w-[400px] lg:max-w-[400px] h-full overflow-y-scroll no-scrollbar">
            {
              perpConfig ?
                <OpenPositionView perpConfig={perpConfig}/> :
                <div className="rounded-md !gap-6 w-full h-full bg-slate-600 animate-pulse"/>
            }
          </div>
        </div>
      }
      {
        !isLargeScreen &&
        <>
          {renderHeader()}
          <div className="shrink grow overflow-auto flex flex-col">
            {
              mobileTab === "trade" &&
              (
                perpConfig
                  ? <OpenPositionView perpConfig={perpConfig}/>
                  : <div className="p-6 !gap-6 w-full bg-slate-600 animate-pulse"/>
              )
            }
            { mobileTab === "positions" && <PerpPositionTable onTokenClicked={t => t !== token && setToken(t)} className="overflow-y-scroll"/> }
            { mobileTab === "activity" && perpConfig && <TradeActivity token={perpConfig.token}/> }
            {
              mobileTab === "chart" &&
              (
                (isLoading || !perpConfig)
                  ? <div className="rounded-md p-6 !gap-6 w-full h-full bg-slate-600 animate-pulse"></div>
                  : renderChart(perpConfig)
              )
            }
            { mobileTab === "profile" &&
              <div className="overflow-y-scroll">
                <UserStatsView />
              </div>
            }
          </div>
          <Tabs<string>
            className="w-full min-h-[66px] !border-0"
            options={mobileTabs}
            value={mobileTab}
            onChange={setMobileTab} />
        </>
      }
    </div>
  );
};

Page.getLayout = function getLayout(page: ReactElement) {
  return <PrimaryLayout2 skipGeofence={true} fixedHeight={true} grow={true} hidePadding={true}>
    {page}
  </PrimaryLayout2>;
};

export default Page;
