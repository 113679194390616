export const WasabiPerpAbi = [
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "_target",
        "type": "address"
      }
    ],
    "name": "EthTransferFailed",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "IncorrectSwapParameter",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "InsufficientAmountProvided",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "InsufficientAvailablePrincipal",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "InsufficientCollateralReceived",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "InvalidCurrency",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "InvalidPosition",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "InvalidSignature",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "InvalidTargetCurrency",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "InvalidVault",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "LiquidationThresholdNotReached",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "OrderExpired",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "PositionAlreadyTaken",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "PrincipalTooHigh",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "SenderNotTrader",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "SwapFunctionNeeded",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "VaultAlreadyExists",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "WithdrawalNotAllowed",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "WithdrawerNotVault",
    "type": "error"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "pool",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "asset",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "address",
        "name": "vault",
        "type": "address"
      }
    ],
    "name": "NewVault",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "id",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "address",
        "name": "trader",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "payout",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "principalRepaid",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "interestPaid",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "feeAmount",
        "type": "uint256"
      }
    ],
    "name": "PositionClosed",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "id",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "address",
        "name": "trader",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "payout",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "principalRepaid",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "interestPaid",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "feeAmount",
        "type": "uint256"
      }
    ],
    "name": "PositionLiquidated",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "positionId",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "address",
        "name": "trader",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "address",
        "name": "currency",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "address",
        "name": "collateralCurrency",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "downPayment",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "principal",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "collateralAmount",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "feesToBePaid",
        "type": "uint256"
      }
    ],
    "name": "PositionOpened",
    "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "contract IWasabiVault",
        "name": "_vault",
        "type": "address"
      }
    ],
    "name": "addVault",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bool",
        "name": "_unwrapWETH",
        "type": "bool"
      },
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "expiration",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "interest",
            "type": "uint256"
          },
          {
            "components": [
              {
                "internalType": "uint256",
                "name": "id",
                "type": "uint256"
              },
              {
                "internalType": "address",
                "name": "trader",
                "type": "address"
              },
              {
                "internalType": "address",
                "name": "currency",
                "type": "address"
              },
              {
                "internalType": "address",
                "name": "collateralCurrency",
                "type": "address"
              },
              {
                "internalType": "uint256",
                "name": "lastFundingTimestamp",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "downPayment",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "principal",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "collateralAmount",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "feesToBePaid",
                "type": "uint256"
              }
            ],
            "internalType": "struct IWasabiPerps.Position",
            "name": "position",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "address",
                "name": "to",
                "type": "address"
              },
              {
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
              },
              {
                "internalType": "bytes",
                "name": "data",
                "type": "bytes"
              }
            ],
            "internalType": "struct IWasabiPerps.FunctionCallData[]",
            "name": "functionCallDataList",
            "type": "tuple[]"
          }
        ],
        "internalType": "struct IWasabiPerps.ClosePositionRequest",
        "name": "_request",
        "type": "tuple"
      },
      {
        "components": [
          {
            "internalType": "uint8",
            "name": "v",
            "type": "uint8"
          },
          {
            "internalType": "bytes32",
            "name": "r",
            "type": "bytes32"
          },
          {
            "internalType": "bytes32",
            "name": "s",
            "type": "bytes32"
          }
        ],
        "internalType": "struct IWasabiPerps.Signature",
        "name": "_signature",
        "type": "tuple"
      }
    ],
    "name": "closePosition",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_asset",
        "type": "address"
      }
    ],
    "name": "getVault",
    "outputs": [
      {
        "internalType": "contract IWasabiVault",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bool",
        "name": "_unwrapWETH",
        "type": "bool"
      },
      {
        "internalType": "uint256",
        "name": "_interest",
        "type": "uint256"
      },
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "id",
            "type": "uint256"
          },
          {
            "internalType": "address",
            "name": "trader",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "currency",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "collateralCurrency",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "lastFundingTimestamp",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "downPayment",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "principal",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "collateralAmount",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "feesToBePaid",
            "type": "uint256"
          }
        ],
        "internalType": "struct IWasabiPerps.Position",
        "name": "_position",
        "type": "tuple"
      },
      {
        "components": [
          {
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "value",
            "type": "uint256"
          },
          {
            "internalType": "bytes",
            "name": "data",
            "type": "bytes"
          }
        ],
        "internalType": "struct IWasabiPerps.FunctionCallData[]",
        "name": "_swapFunctions",
        "type": "tuple[]"
      }
    ],
    "name": "liquidatePosition",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "id",
            "type": "uint256"
          },
          {
            "internalType": "address",
            "name": "currency",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "targetCurrency",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "downPayment",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "principal",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "minTargetAmount",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "expiration",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "fee",
            "type": "uint256"
          },
          {
            "components": [
              {
                "internalType": "address",
                "name": "to",
                "type": "address"
              },
              {
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
              },
              {
                "internalType": "bytes",
                "name": "data",
                "type": "bytes"
              }
            ],
            "internalType": "struct IWasabiPerps.FunctionCallData[]",
            "name": "functionCallDataList",
            "type": "tuple[]"
          }
        ],
        "internalType": "struct IWasabiPerps.OpenPositionRequest",
        "name": "_request",
        "type": "tuple"
      },
      {
        "components": [
          {
            "internalType": "uint8",
            "name": "v",
            "type": "uint8"
          },
          {
            "internalType": "bytes32",
            "name": "r",
            "type": "bytes32"
          },
          {
            "internalType": "bytes32",
            "name": "s",
            "type": "bytes32"
          }
        ],
        "internalType": "struct IWasabiPerps.Signature",
        "name": "_signature",
        "type": "tuple"
      }
    ],
    "name": "openPosition",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_token",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "_amount",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "_receiver",
        "type": "address"
      }
    ],
    "name": "withdraw",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "wrapWETH",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  }
] as const;