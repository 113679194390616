import {formatUnits } from "viem";
import classNames from "classnames";
import React from "react";
import {TokenSymbol} from "@/components/Perps/TokenSymbol";

export interface Props {
  value: bigint | undefined;
  symbol: string;
  tokenDecimals: number;
  placeholder?: string;
  isLoading?: boolean;
  imageUrl?: string;
  displayTokenSelect?: boolean;
  title?: any;
  currentBalance?: bigint;
}

export const TokenOutput = (
  { value, symbol, tokenDecimals, placeholder, isLoading, imageUrl, displayTokenSelect, title, currentBalance }: Props
) => {
  let displayValue = value ? formatUnits(value, tokenDecimals) : placeholder;
  if (value) {
    displayValue = parseFloat(Number(displayValue).toFixed(4)).toLocaleString();
  }
  return (
    <div className="standard-frame flex flex-row gap-2 justify-between py-1 md:py-4 px-2 text-xl">
      <div className="flex flex-col gap-1 justify-center">
        {title && <div className="text-neutral-content text-xs">{title}</div>}
        {
          isLoading ?
            <div className="flex-grow rounded-md bg-slate-600 animate-pulse h-[32px] w-[150px]"/> :
            <span className={classNames({
              "text-neutral-content text-lg md:text-2xl": !value
            })}>{displayValue}</span>
        }
      </div>
      <div className="flex flex-col items-end gap-2">
        <div></div>
        <TokenSymbol symbol={symbol} imageUrl={imageUrl} displayTokenSelect={displayTokenSelect || false}/>
        {
          currentBalance ?
            <span className="text-xs font-light text-neutral-content">
              Balance: {Number(formatUnits(currentBalance, tokenDecimals)).toLocaleString([], {maximumFractionDigits: 2})}
            </span> :
            <div></div>
        }
      </div>
    </div>
  )
}