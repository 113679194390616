import {PerpQuoteRequest, PerpToken} from "@/components/Perps/types";
import {ChainItem, TransactionChain} from "@/components/Transactions/TransactionChain";
import {useQuery} from "@tanstack/react-query";
import {fetchOrder} from "@/api/perpsDataFetcher";
import {TransactionButtonProps} from "@/components/Transactions/TransactionButton";
import {WasabiPerpAbi} from "@/contract/WasabiPerpAbi";
import React from "react";
import {TransactionReceipt} from "viem";
import {
  WASABI_LONG_POOL_ADDRESS,
  WASABI_SHORT_POOL_ADDRESS,
  WETH_ADDRESS
} from "@/util/chainConstants";
import {TokenAllowanceButton} from "@/components/ERC20/TokenAllowanceButton";
import {toBN} from "@/util/converters";
import {BLAST_USDC, isMainnet} from "@/util/constants";
import {TransactionType} from "@/types/types";

export interface Props extends PerpQuoteRequest {
  enabled: boolean;
  onTransactionSuccess: (lastReceipt?: TransactionReceipt) => any;
  token: PerpToken;
}

export const OpenPositionButton = (props: Props) => {
  const poolAddress = props.side === "long" ? WASABI_LONG_POOL_ADDRESS : WASABI_SHORT_POOL_ADDRESS;
  const isUSDB = props.tokenAddress === BLAST_USDC;
  let displaySide = props.side;
  if (isUSDB) {
    displaySide = props.side === "long" ? "short" : "long";
  }
  let pair = props.token.symbol + "/ETH";
  if (isUSDB) {
    pair = "ETH/USDB";
  }

  const transactionChain: ChainItem[] = [];
  // if (!isMainnet) {
  //   transactionChain.push(
  //     {
  //       name: "Allow Token Transfers",
  //       description: "Allow the transfer of your token to the pool",
  //       stepRenderer: (onSuccess, onFailure) =>
  //         <TokenAllowanceButton
  //           autoStart={true}
  //           spender={poolAddress}
  //           requestMaxValue={true}
  //           amount={toBN(props.downPayment)}
  //           contractAddress={WETH_ADDRESS}
  //           approvalChanged={approved => {
  //             if (approved) {
  //               onSuccess();
  //             }
  //           }}
  //           className="capitalize text-xs w-full"/>
  //     }
  //   );
  // }
  
  transactionChain.push(
    {
      name: "Open Position",
      description: (
        <div className="text-white text-xl">
          Opening <span className={displaySide === "long" ? "text-call" : "text-put"}>{props.leverage}x {displaySide.toUpperCase()}</span> on {pair}
        </div>
      ),
      buttonQuery: () => useQuery({
        queryKey: ["open_position", props.side, props.leverage, props.downPayment, props.tokenAddress, props.speedUp],
        queryFn: async () => {
          const order = await fetchOrder(props);
          const buttonProps: TransactionButtonProps = {
            id: "open_position",
            loadingText: "Opening position...",
            addressOrName: poolAddress,
            functionName: "openPosition",
            contractInterface: WasabiPerpAbi,
            args: [order.request, order.signature],
            overrides: {
              value: (BigInt(order.request.downPayment) + BigInt(order.request.fee))
            },
            enabled: props.enabled
          };
          return buttonProps;
        },
        gcTime: 0,
      })
    }
  );

  return (
    <TransactionChain
      id="open_position"
      title="Open Position"
      className="!rounded-none md:!rounded-md !py-6 md:!py-3"
      onSuccess={r => r !== undefined && props.onTransactionSuccess(r)}
      transactionChain={transactionChain}
      enabled={props.enabled}
      type={TransactionType.TRADE}
    />
  )
}
