import {useAccount, useSignTypedData} from "wagmi";
import {
  AllowanceProvider,
  AllowanceTransfer,
  MaxAllowanceTransferAmount, PERMIT2_ADDRESS,
  PermitSingle
} from "@uniswap/permit2-sdk";
import {AlchemyProvider} from "@ethersproject/providers";
import {ALCHEMY_API_KEY, PERMIT_2, UNIVERSAL_ROUTER} from "@/util/constants";
import {AddressZero} from "@ethersproject/constants";
import {Address} from "viem";
import {useQuery} from "@tanstack/react-query";
import {Button} from "@/components/Button";
import {useEffect} from "react";
import {Permit2Permit} from "@/components/Perps/types";

function toDeadline(expiration: number): number {
  return Math.floor((Date.now() + expiration) / 1000)
}

export interface Props {
  tokenAddress: Address;
  autoStart: boolean;
  onSigned: (permit: Permit2Permit) => void;
  onFailure: Function;
}

export const Permit2Signer = ({tokenAddress, autoStart, onSigned, onFailure}: Props) => {
  const {address} = useAccount();
  const allowanceProvider = new AllowanceProvider(new AlchemyProvider(1, ALCHEMY_API_KEY), PERMIT_2);

  const allowanceData = useQuery({
    queryKey: ["allowance", tokenAddress, address || AddressZero, UNIVERSAL_ROUTER],
    queryFn: async () => await allowanceProvider.getAllowanceData(tokenAddress, address || AddressZero, UNIVERSAL_ROUTER),
    enabled: !!address
  });

  const permitSingle: PermitSingle = {
    details: {
      token: tokenAddress,
      amount: MaxAllowanceTransferAmount.toBigInt(),
      expiration: toDeadline(/* 30 days= */ 1000 * 60 * 60 * 24 * 30),
      nonce: allowanceData.data?.nonce || 0,
    },
    spender: UNIVERSAL_ROUTER,
    sigDeadline: toDeadline(/* 30 minutes= */ 1000 * 60 * 60 * 30),
  };

  const { domain, types, values } = AllowanceTransfer.getPermitData(permitSingle, PERMIT2_ADDRESS, 1)

  const getSignData = () => {
    return {
      domain: {
        name: domain.name,
        chainId: 1,
        version: domain.version,
        salt: domain.salt as `0x${string}` | undefined,
        verifyingContract: domain.verifyingContract as `0x${string}` | undefined,
      },
      types: JSON.parse(JSON.stringify(types)),
      primaryType: "PermitSingle",
      message: JSON.parse(JSON.stringify(values)),
    }
  }

  const {isPending, signTypedDataAsync, isError} = useSignTypedData();

  if (isError) {
    onFailure();
  }

  const onClick = () => {
    if (isPending) {
      return;
    }

    signTypedDataAsync(getSignData()).then((signature) => {
      const permit: Permit2Permit = {
        ...permitSingle,
        signature,
      };
      onSigned(permit);
    });
  }

  useEffect(() => {
    if (autoStart) {
      onClick();
    }},
    [autoStart]);

  return (
    <Button onClick={() => onClick()}
            loading={isPending}>
      {
        isPending
          ? "Waiting on Signature"
          : "Sign For Approval"
      }
    </Button>
  )
}



